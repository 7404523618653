import { createStore } from "vuex";
import { login, getInfo, logout } from "@/api/system/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { getRouters } from "@/api/system/menu";
import { h } from "vue";
import { RouterLink } from "vue-router";
import { NIcon } from "naive-ui";

import {
  AlignBoxMiddleCenter as PrimaryIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  CloudSatelliteConfig as ToolIcon,
  IbmCloudTransitGateway as SexIcon,
  IotConnect as BugIcon,
  Devices as DeviceIcon,
  HeatMap as MapIcon,
  MeterAlt as StateIcon,
  ChartSunburst as ChartIcon,
  Unknown as NoneIcon,
  MapCenter as GpsIcon,
  UserMultiple as UserIcon,
  UserAccess as RoleIcon,
  UserProfileAlt as ManageIcon,
  DataStructured as DeptIcon,
  Application as PositionIcon,
  Code as CodeIcon,
  Api1 as ApiIcon,
  ThunderstormScattered as WeatherIcon,
  Bee as BuggerIcon,
  QuadrantPlot as BaoziIcon,
  CropGrowth as EarthIcon,
  TagEdit as ConfigIcon,
} from "@vicons/carbon";

const icon = {
  PrimaryIcon: PrimaryIcon,
  HomeIcon: HomeIcon,
  MenuIcon: MenuIcon,
  ToolIcon: ToolIcon,
  SexIcon: SexIcon,
  BugIcon: BugIcon,
  DeviceIcon: DeviceIcon,
  MapIcon: MapIcon,
  StateIcon: StateIcon,
  ChartIcon: ChartIcon,
  ChartIcon: ChartIcon,
  NoneIcon: NoneIcon,
  GpsIcon: GpsIcon,
  UserIcon: UserIcon,
  RoleIcon: RoleIcon,
  ManageIcon: ManageIcon,
  DeptIcon: DeptIcon,
  PositionIcon: PositionIcon,
  CodeIcon: CodeIcon,
  ApiIcon: ApiIcon,
  WeatherIcon: WeatherIcon,
  BuggerIcon: BuggerIcon,
  BaoziIcon: BaoziIcon,
  EarthIcon: EarthIcon,
  ConfigIcon: ConfigIcon,
};

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

function mapMenu(data) {
  let menu = [];
  data.forEach((it) => {
    let item = {
      label: () => {
        return h(
          RouterLink,
          { to: `/${it.path}` },
          { default: () => h("span", it.meta.title) }
        );
      },
      key: it.path,
      children: it?.children ? mapMenu(it.children) : undefined,
      icon: renderIcon(icon[it.meta.icon]),
    };
    menu.push(item);
  });
  return menu;
}

export default createStore({
  state: {
    token: getToken(),
    username: "",
    permissions: [],
    expandKeys: [],
    checkedKeys: "",
    menu: [],
    role: "",
  },
  getters: {
    token: (state) => state.token,
    username: (state) => state.username,
    permissions: (state) => state.permissions,
    expandKeys: (state) => state.expandKeys,
    checkedKeys: (state) => state.checkedKeys,
    menu: (state) => state.menu,
    role: (state) => state.role,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USERNAME: (state, username) => {
      state.username = username;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_EXPANDKEYS: (state, expandKeys) => {
      state.expandKeys = expandKeys;
      sessionStorage.setItem("expandKeys", JSON.stringify(expandKeys));
    },
    SET_CHECKEDKEYS: (state, checkedKeys) => {
      state.checkedKeys = checkedKeys;
      sessionStorage.setItem("checkedKeys", checkedKeys);
    },
    SET_MENU: (state, menu) => {
      state.menu = menu;
    },
    SET_ROLE: (state, role) => {
      state.role = role;
    },
  },
  actions: {
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      const code = userInfo.code;
      const uuid = userInfo.uuid;
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid)
          .then((res) => {
            setToken(res.token);
            commit("SET_TOKEN", res.token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            commit("SET_USERNAME", res.user.userName);
            commit("SET_PERMISSIONS", res.permissions);
            commit("SET_ROLE", res.roles[0]);
            sessionStorage.setItem("userInfo", JSON.stringify(res));
            return res;
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            removeToken();
            sessionStorage.clear();
            localStorage.clear();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SetExpandKeys({ commit }, expandKeys) {
      commit("SET_EXPANDKEYS", expandKeys);
    },
    SetCheckedKeys({ commit }, checkedKeys) {
      commit("SET_CHECKEDKEYS", checkedKeys);
    },
    GetMenu({ commit, state }) {
      getRouters().then((res) => {
        let menu = [
          {
            label: () => {
              return h(
                RouterLink,
                { to: "/home" },
                { default: () => h("span", "首页") }
              );
            },
            key: "/home",
            icon: renderIcon(HomeIcon),
          },
        ];
        res.data.forEach((it) => {
          menu.push({
            label: it.meta.title,
            key: it.path,
            icon: renderIcon(icon[it.meta.icon]),
            children: it?.children ? mapMenu(it.children) : undefined,
          });
        });

        commit("SET_MENU", menu);
        if (sessionStorage.getItem("expandKeys")) {
          commit(
            "SET_EXPANDKEYS",
            JSON.parse(sessionStorage.getItem("expandKeys"))
          );
        }
        if (sessionStorage.getItem("checkedKeys")) {
          commit("SET_CHECKEDKEYS", sessionStorage.getItem("checkedKeys"));
        } else {
          commit("SET_CHECKEDKEYS", "/home");
        }
      });
    },
  },
});
