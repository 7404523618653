import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../views/layoutPage.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: () => import("../views/HomePage.vue"),
      },
      {
        path: "/user",
        component: () => import("../views/system/user/index.vue"),
      },
      {
        path: "/role",
        component: () => import("../views/system/role/index.vue"),
      },
      {
        path: "/menu",
        component: () => import("../views/system/menu/index.vue"),
      },
      {
        path: "/dept",
        component: () => import("../views/system/dept/index.vue"),
      },
      {
        path: "/post",
        component: () => import("../views/system/post/index.vue"),
      },
      {
        path: "/lota-equip",
        component: () => import("../views/lotA/equipmentInfo.vue"),
      },
      {
        path: "/lota-map",
        component: () => import("../views/lotA/equipmentMap.vue"),
      },
      {
        path: "/lota-state",
        component: () => import("../views/lotA/equipmentStatus.vue"),
      },
      {
        path: "/lotb-equip",
        component: () => import("../views/lotB/equipmentInfo.vue"),
      },
      {
        path: "/lotb-none",
        component: () => import("../views/lotB/equipmentNone.vue"),
      },
      {
        path: "/lotb-state",
        component: () => import("../views/lotB/equipmentState.vue"),
      },
      {
        path: "/lotb-gps",
        component: () => import("../views/lotB/bindGPS.vue"),
      },
      {
        path: "/lotb-map",
        component: () => import("../views/lotB/equipmentMap.vue"),
      },
      {
        path: "/lotb-analysis",
        component: () => import("../views/lotB/dataAnalysis.vue"),
      },
      {
        path: "/lotc-equip",
        component: () => import("../views/lotC/equipmentInfo.vue"),
      },
      {
        path: "/lotc-map",
        component: () => import("../views/lotC/equipmentMap.vue"),
      },
      {
        path: "/lotc-state",
        component: () => import("../views/lotC/equipmentState.vue"),
      },
      {
        path: "/lotc-analysis",
        component: () => import("../views/lotC/dataAnalysis.vue"),
      },
      {
        path: "/lotd-weather",
        component: () => import("../views/lotD/WeatherPot.vue"),
      },
      {
        path: "/lote-bug",
        component: () => import("../views/lotE/BugLight.vue"),
      },
      {
        path: "/lotf-baozi",
        component: () => import("../views/lotF/Baozi.vue"),
      },
      {
        path: "/lotg-earth",
        component: () => import("../views/lotG/earthPage.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../views/loginPage.vue"),
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/notFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
