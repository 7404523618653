import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import naive from "naive-ui";
import SvgIcon from "./components/lt-svg-icon/lt-svg-icon.vue";
import { getToken } from "@/utils/auth";
import { createDiscreteApi } from "naive-ui/lib";

const app = createApp(App);
app.use(store).use(naive).use(router).mount("#app");

app.component("svg-icon", SvgIcon);
const req = require.context("./assets/icons/svg", false, /\.svg$/);

const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
requireAll(req);

const loadingBar = createDiscreteApi(["loadingBar"]).loadingBar;

router.beforeEach((to, from, next) => {
  loadingBar.start();
  if (to.path != "/login" && getToken()) {
    store.dispatch("GetInfo").then((res) => {
      store.dispatch("GetMenu").then((res1) => {
        setTimeout(() => {
          next();
          loadingBar.finish();
        }, 100);
      });
    });
  } else if (to.path != "/login" && !getToken()) {
    next({ path: "/login" });
    loadingBar.finish();
  } else {
    next();
    loadingBar.finish();
  }
});
