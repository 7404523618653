<template>
  <n-config-provider :locale="zhCN" :theme-overrides="themeOverrides" :date-locale="dateZhCN">
    <n-loading-bar-provider>
      <n-message-provider>
        <n-dialog-provider>
          <router-view />
        </n-dialog-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup>

import { zhCN, dateZhCN } from 'naive-ui'
import { ref, onMounted } from "vue"

const themeOverrides = ref({
  common: {
    primaryColor: '#48a5e2',
    primaryColorHover: "rgba(72,165,226,0.8)",
    primaryColorPressed: "rgba(72,165,226,0.8)",
    primaryColorSuppl: "rgba(72,165,226,0.8)",
    successColor: '#2dbb74',
    warningColor: '#ff951a',
    errorColor: '#ff1e0a',
    infoColor: '#48a5e2',
    infoColorHover: "rgba(72,165,226,0.8)",
    infoColorPressed: "rgba(72,165,226,0.8)",
    infoColorSuppl: "rgba(72,165,226,0.8)",
  },
  Menu: {
    itemColor: '#48a5e2',
    itemColorHover: "rgba(72,165,226,0.8)",
    itemColorPressed: "#ffffff",
    itemColorSuppl: "#ffffff",
    itemTextColor: '#d1d1d1',
    itemTextColorHover: "#ffffff",
    itemTextColorPressed: "#ffffff",
    itemTextColorSuppl: "#ffffff",
    arrowColor: '#d1d1d1',
    arrowColorHover: '#ffffff',
    arrowColorPressed: '#ffffff',
    arrowColorSuppl: '#ffffff',
    itemIconColor: '#d1d1d1',
    itemIconColorHover: "#ffffff",
    itemIconColorPressed: "#ffffff",
    itemIconColorSuppl: "#ffffff",
  }
})
onMounted(() => {
  console.log('%c农知禾物联网平台 V1.0', 'background: #071c36; color: #e2dc84; padding: 5px;border-radius: 5px;');
})
</script>
<style lang="scss">
@import "~@/assets/styles/index.scss";

* {
  margin: 0;
  padding: 0;

}

body,
html {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0
  }

}

:root {
  @font-face {
    font-family: "font1";
    font-weight: 700;
    src: url("//at.alicdn.com/wf/webfont/aXtffPNJuoQp/EEKgehyeXGbO.woff2") format("woff2"),
      url("//at.alicdn.com/wf/webfont/aXtffPNJuoQp/uRo4lgHCsi49.woff") format("woff");
    font-display: swap;
  }
}

.anchorBL {
  display: none;
}
</style>
